.section-footer {
	background: $dark url(../images/footer-bg.png) no-repeat;
	background-position: 80% bottom;
	background-size: 50%;

	@media (max-width: 960px) {
		text-align: center;
	}

	@media (max-width: 768px) {
		padding: 2.5rem 0 23rem;
		background-size: 130%;
		background-position: 0% bottom;
		background-image: url(../images/footer-bg-mobile.png);
		background-position: 0% 90%;
		background-size: 100%;
	}

	.text {
		max-width: 25%;
		font-size: 1rem;
		line-height: 1.87;
		@media (max-width: 768px) {
			max-width: 100%;
			font-size: 1rem;
			line-height: 1.87;
		}

		p:not(:last-child) {
			margin-bottom: 1.875rem;
		}
	}
}
