.section {
	&-main {
		padding: 8rem 0;
		background-image: url(../images/main-bg.png);
		background-repeat: no-repeat;
		background-position: center top;

		@media (max-width: 1200px) {
			background-size: cover;
		}

		@media (max-width: 500px) {
			background-size: 120%;
		}

		.logo img {
			width: 100%;
		}

		.grid {
			@media (max-width: 500px) {
				grid-template-columns: 1fr;
			}
		}

		@media (max-width: 500px) {
			padding-top: 2rem;
			background-position: center top 96%;
			background-image: url(../images/main-bg-mobile.png);
		}

		.stretchy-wraper.wrapper-50 {
			height: 100%;
			width: 100%;
			position: relative;
			top: 7rem;
			z-index: 100;

			@media (max-width: 500px) {
				top: 5rem;
			}
		}

		.main-description {
			font-size: 1.25rem;
			line-height: 1.8;
		}
	}
}
