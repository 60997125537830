$dark: #000101;
$golden: #eca944;

$gray: #8a8a8a;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pirata+One&display=swap');

@mixin Roboto {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: normal;
}

@mixin Pirata {
	font-family: 'Pirata One', cursive;
	font-style: normal;
	font-weight: normal;
}

@mixin absoluteCentred {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	margin: auto;
	height: fit-content;
}

@mixin afterElem {
	content: '';
	position: absolute;
	display: block;
}

@mixin scewBorderBottom {
	position: relative;
	&::after {
		@include afterElem;
		width: 0;
		height: 0;
		bottom: 0;
		left: 0;
		border-style: solid;
		border-width: 5vw 0 0 99vw;
		border-color: transparent transparent transparent #ffffff;
	}
}

@mixin scewBorderTop {
	position: relative;
	&::before {
		@include afterElem;
		width: 0;
		height: 0;
		top: 0;
		right: 0;
		border-style: solid;
		border-width: 0 99vw 5vw 0;
		border-color: transparent #ffffff;
		z-index: 100;
	}
}
