* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	border: 0;
	outline: none;
	list-style: none;
}
@import './reset';
@import './variables';
body {
	background-color: $dark;
	color: #fff;
}
.app {
	max-width: 100%;
	overflow: hidden;
}
.container {
	width: 100%;
	max-width: 1200px;
	margin: auto;
	padding: 0 15px;
}
.section {
	padding: 5rem 0;
}
.animation__delay-_5s{
	animation-delay:.5s;
}
.animation__delay-_15s{
	animation-delay:1.5s;
}
.animation__delay-1s{
	animation-delay:1s;
}
.bg-container {
	background: linear-gradient(180deg, #000101 0%, #101820 100%);
	position: relative;

	// &::before,
	// &::after {
	// 	content: '';
	// 	position: absolute;
	// 	width: 100%;
	// 	height: 100%;
	// 	background-repeat: no-repeat;
	// 	// z-index: -1;
	// 	background-size: 25%;

	// 	@media (max-width: 768px) {
	// 		display: none;
	// 	}
	// }

	&::before {
		bottom: -60%;
		left: 0;
		background-image: url(../images/left-decoration.png);
	}

	&::after {
		top: 30%;
		right: -75%;
		background-image: url(../images/right-decoration.png);
	}
}

.centred {
	margin-right: auto;
	margin-left: auto;
	display: block;
	width: fit-content;
}
.max {
	&-width {
		@for $i from 10 through 100 {
			&-#{$i} {
				max-width: $i * 1% !important;
			}
		}
	}
}
.mg {
	&-tp {
		@for $i from 1 through 10 {
			&-#{$i} {
				margin-top: $i * 1rem !important;
			}
		}
	}
	&-bt {
		@for $i from 1 through 10 {
			&-#{$i} {
				margin-bottom: $i * 1rem !important;
			}
		}
	}
	&-centred {
		@include absoluteCentred;
	}
}
.roboto {
	@include Roboto;
}
.pirata {
	@include Pirata;
}

.grid {
	display: grid;
	&-gap {
		@for $i from 1 through 5 {
			&-#{$i} {
				grid-gap: $i * 1rem;
			}
		}
	}
	&-col {
		@for $i from 1 through 5 {
			&-#{$i} {
				grid-template-columns: repeat($i, 1fr);
			}
		}
	}
	&-row {
		@for $i from 1 through 5 {
			&-#{$i} {
				grid-template-rows: repeat($i, 1fr);
			}
		}
	}
}
.stretchy-wraper {
	position: relative;
	width: 100%;
	height: 0;

	&.wrapper-50 {
		padding-bottom: 100%;
		img {
			position: absolute;
			height: 100%;
			width: 100%;
			object-fit: contain;
			object-position: center;
		}
	}
}
.text {
	line-height: 2.14;
	font-size: 0.875rem;
	@media (max-width: 768px) {
		font-size: 0.875rem;
		line-height: 2.14;
	}

	&-header {
		font-size: 3.25rem;

		@media (max-width: 768px) {
			font-size: 2.5rem;
			line-height: 3.1875rem;
		}
	}
	&-centred {
		text-align: center;
	}
	&-big {
		font-size: 2.25rem;
	}
	&-upper {
		text-transform: uppercase !important;
	}
	&-small {
		font-size: 0.85rem;
	}
	&-white {
		color: white;
	}
	&-golden {
		color: $golden;
	}
	&-gray {
		color: $gray;
	}
}
.before {
	position: relative;
	&::before {
		@include afterElem;
	}
}

.main-data p {
	@media (max-width: 768px) {
		font-size: 0.875rem;
		line-height: 2.14;
	}
}

@import 'main-section';
@import 'description';
@import 'historical';
@import 'beer';
@import 'footer';
