.section {
	&-historical {
		padding-bottom: 5rem;
		padding-top: 1rem;

		position: relative;

		@media (max-width: 768px) {
			background-size: 190%;
			background-position: center right 105%;
		}

		.title {
			color: $golden;
		}

		.grid {
			@media (max-width: 768px) {
				grid-template-columns:1fr;
			}
		}

		.remark {
			text-decoration: underline;
			margin-top: 2.8125rem;
		}
	}
}
