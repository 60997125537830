@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pirata+One&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  outline: none;
  list-style: none; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  background-color: #000101;
  color: #fff; }

.app {
  max-width: 100%;
  overflow: hidden; }

.container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 0 15px; }

.section {
  padding: 5rem 0; }

.animation__delay-_5s {
  -webkit-animation-delay: .5s;
          animation-delay: .5s; }

.animation__delay-_15s {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s; }

.animation__delay-1s {
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

.bg-container {
  background: linear-gradient(180deg, #000101 0%, #101820 100%);
  position: relative; }
  .bg-container::before {
    bottom: -60%;
    left: 0;
    background-image: url(../../static/media/left-decoration.a971a166.png); }
  .bg-container::after {
    top: 30%;
    right: -75%;
    background-image: url(../../static/media/right-decoration.1176b56c.png); }

.centred {
  margin-right: auto;
  margin-left: auto;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.max-width-10 {
  max-width: 10% !important; }

.max-width-11 {
  max-width: 11% !important; }

.max-width-12 {
  max-width: 12% !important; }

.max-width-13 {
  max-width: 13% !important; }

.max-width-14 {
  max-width: 14% !important; }

.max-width-15 {
  max-width: 15% !important; }

.max-width-16 {
  max-width: 16% !important; }

.max-width-17 {
  max-width: 17% !important; }

.max-width-18 {
  max-width: 18% !important; }

.max-width-19 {
  max-width: 19% !important; }

.max-width-20 {
  max-width: 20% !important; }

.max-width-21 {
  max-width: 21% !important; }

.max-width-22 {
  max-width: 22% !important; }

.max-width-23 {
  max-width: 23% !important; }

.max-width-24 {
  max-width: 24% !important; }

.max-width-25 {
  max-width: 25% !important; }

.max-width-26 {
  max-width: 26% !important; }

.max-width-27 {
  max-width: 27% !important; }

.max-width-28 {
  max-width: 28% !important; }

.max-width-29 {
  max-width: 29% !important; }

.max-width-30 {
  max-width: 30% !important; }

.max-width-31 {
  max-width: 31% !important; }

.max-width-32 {
  max-width: 32% !important; }

.max-width-33 {
  max-width: 33% !important; }

.max-width-34 {
  max-width: 34% !important; }

.max-width-35 {
  max-width: 35% !important; }

.max-width-36 {
  max-width: 36% !important; }

.max-width-37 {
  max-width: 37% !important; }

.max-width-38 {
  max-width: 38% !important; }

.max-width-39 {
  max-width: 39% !important; }

.max-width-40 {
  max-width: 40% !important; }

.max-width-41 {
  max-width: 41% !important; }

.max-width-42 {
  max-width: 42% !important; }

.max-width-43 {
  max-width: 43% !important; }

.max-width-44 {
  max-width: 44% !important; }

.max-width-45 {
  max-width: 45% !important; }

.max-width-46 {
  max-width: 46% !important; }

.max-width-47 {
  max-width: 47% !important; }

.max-width-48 {
  max-width: 48% !important; }

.max-width-49 {
  max-width: 49% !important; }

.max-width-50 {
  max-width: 50% !important; }

.max-width-51 {
  max-width: 51% !important; }

.max-width-52 {
  max-width: 52% !important; }

.max-width-53 {
  max-width: 53% !important; }

.max-width-54 {
  max-width: 54% !important; }

.max-width-55 {
  max-width: 55% !important; }

.max-width-56 {
  max-width: 56% !important; }

.max-width-57 {
  max-width: 57% !important; }

.max-width-58 {
  max-width: 58% !important; }

.max-width-59 {
  max-width: 59% !important; }

.max-width-60 {
  max-width: 60% !important; }

.max-width-61 {
  max-width: 61% !important; }

.max-width-62 {
  max-width: 62% !important; }

.max-width-63 {
  max-width: 63% !important; }

.max-width-64 {
  max-width: 64% !important; }

.max-width-65 {
  max-width: 65% !important; }

.max-width-66 {
  max-width: 66% !important; }

.max-width-67 {
  max-width: 67% !important; }

.max-width-68 {
  max-width: 68% !important; }

.max-width-69 {
  max-width: 69% !important; }

.max-width-70 {
  max-width: 70% !important; }

.max-width-71 {
  max-width: 71% !important; }

.max-width-72 {
  max-width: 72% !important; }

.max-width-73 {
  max-width: 73% !important; }

.max-width-74 {
  max-width: 74% !important; }

.max-width-75 {
  max-width: 75% !important; }

.max-width-76 {
  max-width: 76% !important; }

.max-width-77 {
  max-width: 77% !important; }

.max-width-78 {
  max-width: 78% !important; }

.max-width-79 {
  max-width: 79% !important; }

.max-width-80 {
  max-width: 80% !important; }

.max-width-81 {
  max-width: 81% !important; }

.max-width-82 {
  max-width: 82% !important; }

.max-width-83 {
  max-width: 83% !important; }

.max-width-84 {
  max-width: 84% !important; }

.max-width-85 {
  max-width: 85% !important; }

.max-width-86 {
  max-width: 86% !important; }

.max-width-87 {
  max-width: 87% !important; }

.max-width-88 {
  max-width: 88% !important; }

.max-width-89 {
  max-width: 89% !important; }

.max-width-90 {
  max-width: 90% !important; }

.max-width-91 {
  max-width: 91% !important; }

.max-width-92 {
  max-width: 92% !important; }

.max-width-93 {
  max-width: 93% !important; }

.max-width-94 {
  max-width: 94% !important; }

.max-width-95 {
  max-width: 95% !important; }

.max-width-96 {
  max-width: 96% !important; }

.max-width-97 {
  max-width: 97% !important; }

.max-width-98 {
  max-width: 98% !important; }

.max-width-99 {
  max-width: 99% !important; }

.max-width-100 {
  max-width: 100% !important; }

.mg-tp-1 {
  margin-top: 1rem !important; }

.mg-tp-2 {
  margin-top: 2rem !important; }

.mg-tp-3 {
  margin-top: 3rem !important; }

.mg-tp-4 {
  margin-top: 4rem !important; }

.mg-tp-5 {
  margin-top: 5rem !important; }

.mg-tp-6 {
  margin-top: 6rem !important; }

.mg-tp-7 {
  margin-top: 7rem !important; }

.mg-tp-8 {
  margin-top: 8rem !important; }

.mg-tp-9 {
  margin-top: 9rem !important; }

.mg-tp-10 {
  margin-top: 10rem !important; }

.mg-bt-1 {
  margin-bottom: 1rem !important; }

.mg-bt-2 {
  margin-bottom: 2rem !important; }

.mg-bt-3 {
  margin-bottom: 3rem !important; }

.mg-bt-4 {
  margin-bottom: 4rem !important; }

.mg-bt-5 {
  margin-bottom: 5rem !important; }

.mg-bt-6 {
  margin-bottom: 6rem !important; }

.mg-bt-7 {
  margin-bottom: 7rem !important; }

.mg-bt-8 {
  margin-bottom: 8rem !important; }

.mg-bt-9 {
  margin-bottom: 9rem !important; }

.mg-bt-10 {
  margin-bottom: 10rem !important; }

.mg-centred {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

.roboto {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal; }

.pirata {
  font-family: 'Pirata One', cursive;
  font-style: normal;
  font-weight: normal; }

.grid {
  display: grid; }
  .grid-gap-1 {
    grid-gap: 1rem; }
  .grid-gap-2 {
    grid-gap: 2rem; }
  .grid-gap-3 {
    grid-gap: 3rem; }
  .grid-gap-4 {
    grid-gap: 4rem; }
  .grid-gap-5 {
    grid-gap: 5rem; }
  .grid-col-1 {
    grid-template-columns: repeat(1, 1fr); }
  .grid-col-2 {
    grid-template-columns: repeat(2, 1fr); }
  .grid-col-3 {
    grid-template-columns: repeat(3, 1fr); }
  .grid-col-4 {
    grid-template-columns: repeat(4, 1fr); }
  .grid-col-5 {
    grid-template-columns: repeat(5, 1fr); }
  .grid-row-1 {
    grid-template-rows: repeat(1, 1fr); }
  .grid-row-2 {
    grid-template-rows: repeat(2, 1fr); }
  .grid-row-3 {
    grid-template-rows: repeat(3, 1fr); }
  .grid-row-4 {
    grid-template-rows: repeat(4, 1fr); }
  .grid-row-5 {
    grid-template-rows: repeat(5, 1fr); }

.stretchy-wraper {
  position: relative;
  width: 100%;
  height: 0; }
  .stretchy-wraper.wrapper-50 {
    padding-bottom: 100%; }
    .stretchy-wraper.wrapper-50 img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center; }

.text {
  line-height: 2.14;
  font-size: 0.875rem; }
  @media (max-width: 768px) {
    .text {
      font-size: 0.875rem;
      line-height: 2.14; } }
  .text-header {
    font-size: 3.25rem; }
    @media (max-width: 768px) {
      .text-header {
        font-size: 2.5rem;
        line-height: 3.1875rem; } }
  .text-centred {
    text-align: center; }
  .text-big {
    font-size: 2.25rem; }
  .text-upper {
    text-transform: uppercase !important; }
  .text-small {
    font-size: 0.85rem; }
  .text-white {
    color: white; }
  .text-golden {
    color: #eca944; }
  .text-gray {
    color: #8a8a8a; }

.before {
  position: relative; }
  .before::before {
    content: '';
    position: absolute;
    display: block; }

@media (max-width: 768px) {
  .main-data p {
    font-size: 0.875rem;
    line-height: 2.14; } }

.section-main {
  padding: 8rem 0;
  background-image: url(../../static/media/main-bg.2f980d58.png);
  background-repeat: no-repeat;
  background-position: center top; }
  @media (max-width: 1200px) {
    .section-main {
      background-size: cover; } }
  @media (max-width: 500px) {
    .section-main {
      background-size: 120%; } }
  .section-main .logo img {
    width: 100%; }
  @media (max-width: 500px) {
    .section-main .grid {
      grid-template-columns: 1fr; } }
  @media (max-width: 500px) {
    .section-main {
      padding-top: 2rem;
      background-position: center top 96%;
      background-image: url(../../static/media/main-bg-mobile.dc0cf307.png); } }
  .section-main .stretchy-wraper.wrapper-50 {
    height: 100%;
    width: 100%;
    position: relative;
    top: 7rem;
    z-index: 100; }
    @media (max-width: 500px) {
      .section-main .stretchy-wraper.wrapper-50 {
        top: 5rem; } }
  .section-main .main-description {
    font-size: 1.25rem;
    line-height: 1.8; }

.section-description {
  position: relative;
  padding-bottom: 0; }
  @media (max-width: 768px) {
    .section-description {
      padding-top: 0; } }
  .section-description .text {
    font-size: 1.125rem;
    line-height: 2; }
  .section-description .grid {
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 2rem; }
    @media (max-width: 768px) {
      .section-description .grid {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        grid-gap: 0; } }
  @media (max-width: 768px) {
    .section-description .text-header {
      margin-bottom: 0.8125rem; } }
  @media (max-width: 768px) {
    .section-description .image {
      -webkit-order: 1;
              order: 1;
      width: 90%; } }
  .section-description .stretchy-wraper.wrapper-50 {
    padding-bottom: 125%;
    width: 125%;
    margin-left: -20%; }
  .section-description .info {
    margin-top: 3rem;
    max-width: 77%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem; }
    @media (max-width: 550px) {
      .section-description .info {
        max-width: 100%; } }
    .section-description .info .with-logo {
      padding-left: 10rem;
      position: relative; }
      .section-description .info .with-logo::before {
        content: '';
        position: absolute;
        width: 141px;
        height: 95px;
        background-image: url(../../static/media/logo-description.9a01a016.png);
        background-repeat: no-repeat;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto; }

.section-historical {
  padding-bottom: 5rem;
  padding-top: 1rem;
  position: relative; }
  @media (max-width: 768px) {
    .section-historical {
      background-size: 190%;
      background-position: center right 105%; } }
  .section-historical .title {
    color: #eca944; }
  @media (max-width: 768px) {
    .section-historical .grid {
      grid-template-columns: 1fr; } }
  .section-historical .remark {
    text-decoration: underline;
    margin-top: 2.8125rem; }

.section-beer {
  background-image: url(../../static/media/beer-bg.d1d281d9.png);
  background-size: contain;
  padding: 6rem 0; }
  @media (max-width: 768px) {
    .section-beer {
      background-size: cover; } }
  .section-beer .text {
    line-height: 1.57; }
    @media (max-width: 768px) {
      .section-beer .text {
        line-height: 1.79; } }
  @media (max-width: 960px) {
    .section-beer .grid {
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap;
      overflow: auto; }
      .section-beer .grid::-webkit-scrollbar {
        display: none; } }

.section-footer {
  background: #000101 url(../../static/media/footer-bg.2122a3c7.png) no-repeat;
  background-position: 80% bottom;
  background-size: 50%; }
  @media (max-width: 960px) {
    .section-footer {
      text-align: center; } }
  @media (max-width: 768px) {
    .section-footer {
      padding: 2.5rem 0 23rem;
      background-size: 130%;
      background-position: 0% bottom;
      background-image: url(../../static/media/footer-bg-mobile.d29968ae.png);
      background-position: 0% 90%;
      background-size: 100%; } }
  .section-footer .text {
    max-width: 25%;
    font-size: 1rem;
    line-height: 1.87; }
    @media (max-width: 768px) {
      .section-footer .text {
        max-width: 100%;
        font-size: 1rem;
        line-height: 1.87; } }
    .section-footer .text p:not(:last-child) {
      margin-bottom: 1.875rem; }

