.section-beer {
	background-image: url(../images/beer-bg.png);
	background-size: contain;
	padding: 6rem 0;

	@media (max-width: 768px) {
		background-size: cover;
	}

	.text {
		line-height: 1.57;
		@media (max-width: 768px) {
			line-height: 1.79;
		}
	}
	.grid {
		@media (max-width: 960px) {
			flex-direction: row;
			flex-wrap: nowrap;
			overflow: auto;
			&::-webkit-scrollbar {
				display: none;
			}
		}
	}
}
