.section {
	&-description {
		position: relative;
		padding-bottom: 0;

		@media (max-width: 768px) {
			padding-top: 0;
		}
		.text {
			font-size: 1.125rem;
			line-height: 2;
		}
		.grid {
			align-items: center;
			grid-gap: 2rem;

			@media (max-width: 768px) {
				display: flex;
				flex-direction: column;
				grid-gap: 0;
			}
		}

		.text-header {
			@media (max-width: 768px) {
				margin-bottom: 0.8125rem;
			}
		}

		.image {
			@media (max-width: 768px) {
				order: 1;
				width: 90%;
			}
		}
		.stretchy-wraper.wrapper-50 {
			padding-bottom: 125%;
			width: 125%;
			margin-left: -20%;
		}

		.info {
			margin-top: 3rem;
			max-width: 77%;

			display: flex;
			flex-direction: column;
			gap: 1.5rem;

			@media (max-width: 550px) {
				max-width: 100%;
			}

			.with-logo {
				padding-left: 10rem;
				position: relative;
				&::before {
					content: '';
					position: absolute;
					width: 141px;
					height: 95px;
					background-image: url(../images/logo-description.png);
					background-repeat: no-repeat;
					left: 0;
					top: 0;
					bottom: 0;
					margin: auto;
				}
			}
		}
	}
}
